import React from "react";

const About = () => {
  return (
    <section className="section about-section">
      <h1 className="section-title">about</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, sit
        voluptatum id distinctio non in atque aperiam repellat nobis? Recusandae
        maiores laboriosam labore quam, enim repudiandae ducimus commodi
        veritatis odio?
      </p>
    </section>
  );
};

export default About;
